/* Home.css */

/* General styles */
.home {
    padding: 15rem !important;
    text-align: left;
    min-height: 100dvh;
    min-width: 100dvw;
    background-color: #d4fee2;
}

.welcome-message {
    font-size: 4rem;
    color: black;
    font-weight: 900;
    text-align: center;
}

/* Media query for screens with max-width: 767px */
@media (max-width: 767px) {
    .home {
        padding: 5rem !important; /* Adjust padding for smaller screens */
        min-height: auto; /* Allow height to adjust based on content for smaller screens */
        min-width: auto; /* Allow width to adjust based on content for smaller screens */
    }

    .welcome-message {
        font-size: 2rem; /* Adjust font-size for smaller screens */
    }
}
