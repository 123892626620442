/* BlogDetail.css */
.blog-detail-container {
  text-align: left;
  padding: 15rem;
  background-color: #d4fee2;
}

.blog-detail-content {
  margin-top: 1rem;
  color: black;
  position: relative;
}

/* Styling for the code container and copy button */
.code-container {
  position: relative;
  border: 1px black solid;
}

.icon-container {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  display: flex;
  align-items: center;
}

.icon {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
}

/* Media query for screens with max-width: 767px */
@media (max-width: 767px) {
  .blog-detail-container {
    padding: 5rem 2rem; /* Adjust padding for smaller screens */
  }

  .code-container {
    border: 1px #555 solid; /* Adjust border color for smaller screens */
  }

  .icon-container {
    top: 0.2rem; /* Adjust top position for smaller screens */
    right: 0.5rem; /* Adjust right position for smaller screens */
  }

  .icon-container:hover .copy-button:hover,
  .icon-container:hover .icon:hover {
    background-color: transparent; /* Change background color on hover for smaller screens */
  }
}
