/* BlogList.css */

/* General styles */
.blog-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 4rem;
}

.blog-post {
    border: 1px solid #000000;
    padding: 2rem;
    border-radius: 2rem;
}

.blog-image {
    padding: 1rem;
    width: 100%;
    height: 80%;
    border-radius: 3rem;
    object-fit: cover;
}

.blog-title {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
}

.blog-meta {
    margin: 5px 0;
    color: #666;
    text-align: center;
}

/* Media query for screens with max-width: 767px */
@media (max-width: 767px) {
    .blog-grid {
        grid-template-columns: fit-content(100%);
        gap: 2rem;
    }

    .blog-post {
        padding: 1rem;
        border-radius: 1rem;
    }

    .blog-image {
        height: 50%;
        width: 100%;
        border-radius: 2rem;
        object-fit: cover;
    }

    .blog-title {
        font-size: 1rem;
    }

    .blog-meta {
        font-size: 0.7rem;
        margin: 3px 0;
    }
}